
import { useState, useEffect } from "react";
import starsImg from '../assets/img/icons/reviewsStar.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Badge,
  Card,
  Col,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { collection, getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const Reviews = () => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
      const fetchReviews = async () => {
        try {
          const surveyCollection = collection(db, "survey");
          const q = query(surveyCollection, where("surveyType", "==", "RATING"));
          const surveySnapshot = await getDocs(q);
          
          const results = [];
          
          for (const surveyDoc of surveySnapshot.docs) {
            const resultsCollection = collection(surveyDoc.ref, "result");
            const resultsSnapshot = await getDocs(resultsCollection);
            
            if (!resultsSnapshot.empty) {
              resultsSnapshot.forEach(resultDoc => {
                const data = resultDoc.data();
                results.push({
                  id: resultDoc.id,
                  surveyId: surveyDoc.id,
                  name: data.name,
                  ratings: data.ratings,
                  review: data.review,
                  ratingStatus: data.ratingStatus // Assuming this field exists
                });
              });
            }
          }
  
          setReviews(results);
        } catch (error) {
          console.error("Error fetching reviews: ", error);
        }
      };
  
      fetchReviews();
    }, []);
  
    const handleStatusChange = async (surveyId, resultId, status) => {
      try {
        const resultDocRef = doc(db, "survey", surveyId, "result", resultId);
        await updateDoc(resultDocRef, { ratingStatus: status });
        setReviews(prevReviews => 
          prevReviews.map(review => 
            review.id === resultId ? { ...review, ratingStatus: status } : review
          )
        );
      } catch (error) {
        console.error("Error updating status: ", error);
      }
    };
  
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
      <Row className="gx-3">
          <Col className="mb-5 mb-xl-0" xl="9">
          <h3 className="text-white text-xl mb-4">Bewertungen</h3>
          </Col>
          
        </Row>
        <Row className="revCButtons mb-4">
        <Col className="" xl="12">
            <a href="#">Alle Bewertungen</a>
            <a href="#">Veröffentlicht</a>
            <a href="#">Abgelehnt</a>
            </Col>
        </Row>
        <Row className="gx-3 reviewsContent mb-3">
        {reviews.length > 0 ? (
        reviews.map((review) => (
            <Col className="mb-3" xl="3">
            
                <div key={review.id}>
                    <div className="rUsrImage"></div>
                    <p className="rUsrName">{review.name}</p>
                    <p className="rDate">June 4 2022, 08:45 AM</p>
                    <p className="ratingNumber">{Array.from({ length: review.ratings }, (_, index) => (
                <span key={index}>&#9733;</span> // Unicode star symbol
              ))}</p>
                    <p className="rComment">{review.review}</p>
                    <ul className="reviewsBtn">
                    <li><button onClick={() => handleStatusChange(review.surveyId, review.id, "Declined")}>Decline</button></li>
                        <li><button onClick={() => handleStatusChange(review.surveyId, review.id, "Approved")}>Approve</button></li>                        
                    </ul>
                </div>
            </Col>
           ))
        ) : (
          <p>No reviews available.</p>
        )}
        </Row>
        <Row>
       
      
        </Row>
      </Container>
    </>
  );
};

export default Reviews;
