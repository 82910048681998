import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, deleteDoc, Timestamp  } from "firebase/firestore";
import { db } from "../firebase-config";
import Header from "components/Headers/Header";
import {
  Badge,
  Card,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { format } from "date-fns";
import { flattenUserData, formatInvestmentDate } from "../utils/userUtils";
import { deleteWalletEntry, updateWalletEntry, fetchWalletHistory, fetchWallet } from "../services/firebaseService";
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button, Modal } from 'react-bootstrap';
import AddNewUserBg from '../assets/img/icons/modalHeaderBg.png'; 
import trashIcon from '../assets/img/icons/trash.png';   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { addTradingHistory, editTradingHistory, deleteTradingHistory } from "../services/tradingHistoryService";
import { fetchWalletData, fetchCoinsData, fetchTradingHistory, calculateTotalBalance, formatBalance  } from "../utils/walletUtils";

const UserProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [telefonNumer, setTelefonNumer] = useState("");
  const [investmentDate, setInvestmentDate] = useState("");
  const [startKapital, setStartKapital] = useState("");
  const [goalCapital, setGoalCapital] = useState("");
  const [goalDate, setGoalDate] = useState("");
  const [walletHistory, setWalletHistory] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [show, setShow] = useState(false);
  const [showWall, setShowWall] = useState(false);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [newEntryProfit, setNewEntryProfit] = useState("");
  const [editingEntry, setEditingEntry] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCoins, setFilteredCoins] = useState(coins);
  const [role, setRole] = useState("Kunde");
  const [balance, setBalance] = useState(0);

  const fetchDataAndCalculateBalance = async () => {
    try {
      // Fetch Wallet Data
      const walletData = await fetchWalletData(id);
      
      // Fetch Coin Prices
      const coinsData = await fetchCoinsData();
      
      // Fetch Trading History Data
      const tradingHistory = await fetchTradingHistory(id);
      
      // Calculate Total Balance (wallet + trading history)
      const totalBalance = calculateTotalBalance(walletData, coinsData, tradingHistory);
      
      // Format and set the balance
      const formattedBalance = formatBalance(totalBalance);
      setBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching data or calculating balance:", error);
      toast.error("Error calculating balance: " + error.message);
    }
  };

  useEffect(() => {
       fetchDataAndCalculateBalance();
  }, [id]);

  const handleClose = () => {
    setShow(false);
    setEditingEntry(null);
    setNewEntryProfit("");
  };
  const handleShow = () => setShow(true);
  const handleCloseWall = () => setShowWall(false);
  const handleShowWall = async () => {
    try {
      const coinsCollection = collection(db, "coins");
      const coinsSnapshot = await getDocs(coinsCollection);
      const coinsList = coinsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        currency: doc.id,
      }));
      setCoins(coinsList);
      setShowWall(true);
    } catch (error) {
      console.error("Error fetching coins: ", error);
    }
  };

  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px 0',
    color: 'white', // Ensure the text is readable over the background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const [newEntryDate, setNewEntryDate] = useState("");
  const [newEntryProfitInPercentage, setNewEntryProfitInPercentage] = useState("");
  const [newEntryWalletState, setNewEntryWalletState] = useState("");
  const [newEntryCryptoValue, setNewEntryCryptoValue] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = doc(db, "users", id);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const flattenedUserData = flattenUserData(userData);

          setUser(userData);
          setName(userData.name || ""); 
          setEmail(userData.email || ""); 
          setDob(flattenedUserData.dob || ""); 
          setTelefonNumer(userData.phone || ""); 
          setInvestmentDate(flattenedUserData.investmentDate || ""); 
          setStartKapital(flattenedUserData.startKapital || ""); 
          setGoalCapital(flattenedUserData.goalCapital || ""); 
          setGoalDate(flattenedUserData.goalDate || ""); 
          
          // Set role, defaulting to 'Kunde' if not present
          setRole(userData.role || "Kunde");

          const walletHistoryData = await fetchWalletHistory(id);
          setWalletHistory(walletHistoryData);

          const walletData = await fetchWallet(id);
          setWallet(walletData);

          const initialValues = {};
          walletData.forEach((item) => {
            initialValues[item.id] = item.cryptoValue;
          });
          setInputValues(initialValues);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  const handleSave = async () => {
    try {
      const userDoc = doc(db, "users", id);
  
      const userSnapshot = await getDoc(userDoc);
      if (!userSnapshot.exists()) {
        toast.error("User data not found.");
        return;
      }
  
      const convertToDateObj = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return new Date(`${year}-${month}-${day}`);
      };
  
      let convertedInvestmentDate = null;
      let convertedGoalDate = null;
  
      if (investmentDate) {
        const investmentDateObj = convertToDateObj(investmentDate);
        if (!isNaN(investmentDateObj.getTime())) {
          convertedInvestmentDate = Timestamp.fromDate(investmentDateObj);
        } else {
          toast.error("Invalid investment date.");
          return;
        }
      }
  
      if (goalDate) {
        const goalDateObj = convertToDateObj(goalDate);
        if (!isNaN(goalDateObj.getTime())) {
          convertedGoalDate = Timestamp.fromDate(goalDateObj);
        } else {
          toast.error("Invalid goal date.");
          return;
        }
      }
  
      await updateDoc(userDoc, {
        name,
        email,
        telefonNumer,
        "investmentDetails.startCapital": startKapital,  
        "investmentDetails.goalCapital": goalCapital,  
        "investmentDetails.investmentDate": convertedInvestmentDate, 
        "investmentDetails.goalDate": convertedGoalDate, 
        role,
      });
  
      toast.success("Profildaten erfolgreich aktualisiert.");
    } catch (error) {
      toast.error("Error saving data: " + error.message);
    }
  };
  

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleCheckboxChange = (currency) => {
    setSelectedCurrencies((prev) =>
      prev.includes(currency)
        ? prev.filter((item) => item !== currency)
        : [...prev, currency]
    );
  };

  const handleAddWallet = async (e) => {
    e.preventDefault();
    try {
      const walletCollection = collection(db, "users", id, "wallet");
      const promises = selectedCurrencies.map((currency) => {
        const selectedCoin = coins.find(coin => coin.currency === currency);
        if (!selectedCoin) {
          toast.error(`Coin data for currency ${currency} not found`);
          return;
        }
  
        const newEntry = {
          createdAt: new Date(),
          updatedAt: new Date(),
          coinData: {
            color: selectedCoin.color || "defaultColor", 
            isFavorite: selectedCoin.isFavorite || false,
            order: selectedCoin.order !== undefined ? selectedCoin.order : 0, 
            symbol: selectedCoin.symbol || "unknown"
          },
          cryptoValue: 0, // Set default value to 0
        };
  
        return addDoc(walletCollection, newEntry);
      });
  
      await Promise.all(promises);
      toast.success("Neuer Gewinn erfolgreich hinzugefügt.");
  
      // Fetch updated wallet data
      const walletData = await fetchWallet(id);
      setWallet(walletData);
      setSelectedCurrencies([]);
      setShowWall(false);
      fetchDataAndCalculateBalance();
    } catch (error) {
      toast.error("Error adding wallet entries: " + error.message);
    }
  };
  

  const handleDelete = async (entryId) => {
    if (typeof entryId !== "string") {
      toast.error("Invalid entry ID");
      return;
    }
  
    try {
      await deleteWalletEntry(id, entryId);
      setWallet(wallet.filter(item => item.id !== entryId));
      toast.success("Kryptowährung erfolgreich gelöscht.");
    } catch (error) {
      toast.error("Error deleting entry: " + error.message);
    }
  };

  const handleChange = (entryId, newValue) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [entryId]: newValue,
    }));
  };

  const handleBlur = async (entryId, newValue) => {
    if (newValue === null || newValue.trim() === "") {
      return;
    }

    await updateWalletEntry(id, entryId, newValue);
    setWallet(wallet.map((item) =>
      item.id === entryId ? { ...item, cryptoValue: newValue } : item
    ));
    fetchDataAndCalculateBalance();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const success = editingEntry
      ? await editTradingHistory(id, editingEntry.id, newEntryProfit, editingEntry)
      : await addTradingHistory(id, newEntryProfit);
    
    if (success) {
      const walletHistoryData = await fetchWalletHistory(id); // Fetch updated data
      setWalletHistory(walletHistoryData);
      handleClose();
      fetchDataAndCalculateBalance();
    }
  };

  const handleEditClick = (entry) => {
    setEditingEntry(entry);
    setNewEntryProfit(entry.tradingValue);
    setShow(true);
  };

  const handleDeleteClick = async (entryId) => {
    const success = await deleteTradingHistory(id, entryId);
    if (success) {
      setWalletHistory(walletHistory.filter(entry => entry.id !== entryId));
      fetchDataAndCalculateBalance();
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
       <button onClick={() => handleEditClick(rowData)} className="editProfitBtn">
       Bearbeiten
                    </button>
        <button onClick={() => handleDeleteClick(rowData.id)} className="deleteProfitBtn">
                      <img src={trashIcon} alt="Delete"/>
                    </button>
      </React.Fragment>
    );
  };
  const tradingValueTemplate = (rowData) => {
    const value = rowData.tradingValue;
    const className = value >= 0 ? 'positive' : 'negative';
    
    return (
      <span className={className}>
        {value}
      </span>
    );
  };

  const onSelectionChange = (currency) => {
    handleCheckboxChange(currency);
  };

  const symbolBodyTemplate = (rowData) => {
    return (
      <div>
        <Checkbox
          inputId={rowData.symbol}
          value={rowData.currency}
          checked={selectedCurrencies.includes(rowData.currency)}
          onChange={() => onSelectionChange(rowData.currency)}
        />
        <label htmlFor={rowData.symbol} style={{ marginLeft: '8px' }}>
        <img
        src={rowData.logo}
        alt={rowData.symbol}
        style={{ width: '20px', height: '20px', marginRight: '30px' }}
      />
          <span>{rowData.symbol}</span>
          <span>{rowData.name}</span>
        </label>
      </div>
    );
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredCoins(
        coins.filter(coin => {
          const symbol = coin.symbol ? coin.symbol.toLowerCase() : "";
          const name = coin.name ? coin.name.toLowerCase() : "";
          return symbol.includes(searchValue.toLowerCase()) ||
                 name.includes(searchValue.toLowerCase());
        })
      );
    } else {
      setFilteredCoins(coins);
    }
  }, [searchValue, coins]);


  
  if (!user) return <div>Loading...</div>;

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="card-bgColor portfolioUserSection">
        <Row className="">
          <Col xl="8"><h2>Benutzerportfolio</h2></Col>
          <Col className="text-white text-right">Total portfolio value: <span className="totPortfolio">{balance} CHF</span></Col>
          </Row>
          
          <Row className="">
            <Col>
              <Row>
                <Col>
                  <label>
                    Name / Vorname:
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </Col>
                <Col>
                <label>
                  Rolle:
                  <Input
                    type="select"
                    id="role"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Kunde">Kunde</option>
                  </Input>
                </label>
              </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    Email:
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                </Col>
                <Col>
                  <label>
                    Telefonnumer:
                    <input
                      type="text"
                      value={telefonNumer}
                      onChange={(e) => setTelefonNumer(e.target.value)}
                    />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <label>Investitionsdatum:
                    <input type="text" value={investmentDate}
                    onChange={(e) => setInvestmentDate(e.target.value)} /></label>
                </Col>
                <Col>
                  <label className="inputForChf">Start Kapital:
                    <input type="number" value={startKapital}
                    onChange={(e) => setStartKapital(e.target.value)} /></label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="inputForChf">ZIEL:
                    <input type="text" value={goalCapital} 
                    onChange={(e) => setGoalCapital(e.target.value)} /></label>
                </Col>
                <Col>
                  <label>Zieldatum:
                    <input type="text" value={goalDate} 
                    onChange={(e) => setGoalDate(e.target.value)} /></label>
                </Col>
              </Row>
            </Col>
          </Row>
          <button onClick={handleSave}>Aktualisierung</button>
        </div>
        <br />
        <div className="card-bgColor portfolioUserWallet">
          <Row>
            <Col xl="4">
              <h2>Kryptowährungsliste</h2>
              <ul style={{ width: "100%", listStyleType: "none", padding: 0 }} className="cryptoListProfile">
                {wallet.map((item) => (
                  <li key={item.id} style={{ display: "flex", justifyContent: "space-between", padding: "8px 0", alignItems: "center" }}>
                    <span className={item.symbol}>{item.symbol}</span>
                    <span>
                      <input
                        type="number"
                        value={inputValues[item.id] || ""}
                        onChange={(e) => handleChange(item.id, e.target.value)}
                        onBlur={(e) => handleBlur(item.id, e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </span>
                    <button onClick={() => handleDelete(item.id)}>
                      <img src={trashIcon} alt="Delete"/>
                    </button>
                  </li>
                ))}
              </ul>
              <div className="addNewCrypto"><button onClick={handleShowWall}>Neue Krypto hinzufügen</button></div>
            </Col>
            <Col xl="8">
              <h2>Wallet-Verlauf</h2>
              <a href="#" className="addNewData" onClick={handleShow}>+ Neue Daten hinzufügen</a>

              <div className="walletHistoryTable">
                <DataTable value={walletHistory} paginator rows={5} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="createdAt" header="Datum" style={{ width: '25%' }}></Column>
                  <Column field="tradingValue" header="Gewinn in CHF" body={tradingValueTemplate} style={{ width: '25%' }}></Column>
                  <Column body={actionBodyTemplate} header="Aktionen" style={{ width: '25%' }}></Column>
                </DataTable>
              </div>


            </Col>
          </Row>
        </div>
        <Modal className="addNewUserModal addCoinsToUser addNewWalletModal" show={showWall} onHide={handleCloseWall}>
          <Modal.Header style={divStyle}>
            <Modal.Title>Kryptowährungsliste</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleAddWallet}>
          <div className="coinsUserTable">
          <div style={{ marginBottom: '1em' }}>
        <InputText
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Suche"
        />
      </div>
              <DataTable
               value={filteredCoins}
               scrollable
               scrollHeight="770px"
               tableStyle={{ paddingRight: '10px' }} 
              >
                <Column
                  field="symbol"
                  header=""
                  body={symbolBodyTemplate}
                />
              </DataTable>
              </div>
              <Row className="cryptoLsitModalButton">
                <Col>
                  <span onClick={handleCloseWall} className="dicardChng">Verwerfen</span>
                </Col>
                <Col>
                  <Button type="submit" className="saveChanges">Auswählen</Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
        <Modal className="addNewUserModal addNewWalletModal" show={show} onHide={handleClose}>
          <Modal.Header style={divStyle}>
            <Modal.Title>{editingEntry ? "Edit Wallet-Daten" : "Neue Wallet-Daten"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFormSubmit} className="addNewWHistoryFrm">
              <Row>
                <Col>
                  <div>
                    <label>Gewinn in CHF:
                      <input type="number" value={newEntryProfit} onChange={(e) => setNewEntryProfit(e.target.value)} required />
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button onClick={handleClose} className="dicardChng">Verwerfen</button>
                </Col>
                <Col>
                  <button type="submit">{editingEntry ? "Speichern" : "Auswählen"}</button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <ToastContainer />
      </Container>
    </>
  );
};

export default UserProfile;
