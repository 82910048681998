import { collection, setDoc, addDoc, doc, updateDoc,deleteDoc , Timestamp } from "firebase/firestore";
import { db } from "../firebase-config"; // Ensure the correct path to your firebase config
import { toast } from "react-toastify";

export const addTradingHistory = async (userId, newEntryProfit) => {
    try {
      const walletHistoryCollection = collection(db, "users", userId, "tradingHistory");
  
      const newDocRef = doc(walletHistoryCollection);
  
      const newEntry = {
        id: newDocRef.id, 
        tradingValue: parseFloat(newEntryProfit),
        currencyCode: "CHF",
        createdAt: Timestamp.now(), 
        updatedAt: Timestamp.now(), 
      };
  
      await setDoc(newDocRef, newEntry);
  
      toast.success("Neuer Gewinn erfolgreich hinzugefügt.");
      return true;
    } catch (error) {
      console.error("Error adding wallet entry: ", error);
      toast.error("Error adding wallet entry");
      return false;
    }
  };

export const editTradingHistory = async (userId, entryId, newEntryProfit) => {
    try {
      const entryDoc = doc(db, "users", userId, "tradingHistory", entryId);
  
      const updatedEntry = {
        tradingValue: parseFloat(newEntryProfit),
        updatedAt: new Date(), // Optional: Use this if you want to track when the entry was last updated
      };
  
      await updateDoc(entryDoc, updatedEntry);
  
      toast.success("Gewinn erfolgreich aktualisiert.");
      return true;
    } catch (error) {
      console.error("Error updating wallet entry: ", error);
      toast.error("Error updating wallet entry");
      return false;
    }
  };
  


export const deleteTradingHistory = async (userId, entryId) => {
    try {
      const entryDoc = doc(db, "users", userId, "tradingHistory", entryId);
      await deleteDoc(entryDoc);
  
      toast.success("Gewinn erfolgreich gelöscht.");
      return true;
    } catch (error) {
      console.error("Error deleting wallet entry: ", error);
      toast.error("Error deleting wallet entry");
      return false;
    }
  };