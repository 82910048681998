import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, doc, Timestamp } from "firebase/firestore";

const Wallet = ({ userId }) => {
  const [walletData, setWalletData] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        // Reference the 'wallet' subcollection under the specific 'user' document
        const walletRef = collection(doc(db, "users", "1MpxoirT6ycoE0RH0ZG5MdrhPv02"), "wallet");
  
        // Fetch the documents from the wallet subcollection
        const querySnapshot = await getDocs(walletRef);
  
        // Map the documents to JSON objects, excluding createdAt, updatedAt, and id
        const walletItems = querySnapshot.docs.map(doc => {
          const { createdAt, updatedAt, ...dataWithoutTimestamps } = doc.data(); // Destructure and exclude createdAt/updatedAt
  
          // Return only the fields in `dataWithoutTimestamps`, excluding id
          return { ...dataWithoutTimestamps };
        });
  
        // Update state with fetched data
        setWalletData(walletItems);
      } catch (error) {
        console.error("Error fetching wallet data: ", error);
      }
    };
  
    fetchWalletData();
  }, [db, userId]);
  
  

  return (
    <div>
      <h1>User Wallet</h1>
      <pre style={{color:"#fff"}}>{JSON.stringify(walletData, null, 2)}</pre>
    </div>
  );
};

export default Wallet;
