import { Timestamp } from "firebase/firestore"; // Import Firebase Timestamp if needed


export const walletDataArray = [
  {
    "cryptoValue": "1053.157284",
    "coinData": {
      "order": 21,
      "isFavorite": false,
      "color": "#fda018",
      "symbol": "NMT"
    }
  },
  {
    "coinData": {
      "symbol": "GFAL",
      "order": 7,
      "color": "#c34b4b",
      "isFavorite": false
    },
    "cryptoValue": "97380.98174"
  },
  {
    "coinData": {
      "isFavorite": true,
      "order": "2",
      "symbol": "ETH",
      "color": "#dddfff"
    },
    "cryptoValue": "4.333144036"
  },
  {
    "cryptoValue": "111.6567277",
    "coinData": {
      "symbol": "INJ",
      "color": "#6e65e6",
      "isFavorite": false,
      "order": 10
    }
  },
  {
    "cryptoValue": "844.6112871",
    "coinData": {
      "symbol": "MAVIA",
      "color": "#215dd3",
      "order": 42,
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "997.6112735",
    "coinData": {
      "symbol": "ARKM",
      "order": 23,
      "color": "#0c0d0d",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "3070.760979",
    "coinData": {
      "color": "#c93636",
      "isFavorite": false,
      "order": 25,
      "symbol": "MAGIC"
    }
  },
  {
    "coinData": {
      "color": "#ecece4",
      "symbol": "RSC",
      "isFavorite": true,
      "order": 6
    },
    "cryptoValue": "7957.625"
  },
  {
    "cryptoValue": "2663.307524",
    "coinData": {
      "order": 30,
      "isFavorite": false,
      "symbol": "AIOZ",
      "color": "#c2c2c2"
    }
  },
  {
    "coinData": {
      "color": "#f00000",
      "isFavorite": true,
      "symbol": "BTC",
      "order": 1
    },
    "cryptoValue": "0.356858725"
  },
  {
    "coinData": {
      "color": "#e6007a",
      "symbol": "DOT",
      "order": 36,
      "isFavorite": false
    },
    "cryptoValue": "490.2628736"
  },
  {
    "coinData": {
      "order": 26,
      "color": "#d0b9b9",
      "isFavorite": false,
      "symbol": "BEAM"
    },
    "cryptoValue": "78478.14167"
  },
  {
    "cryptoValue": "42.64576237",
    "coinData": {
      "order": 38,
      "symbol": "QNT",
      "isFavorite": false,
      "color": "#fafafa"
    }
  },
  {
    "coinData": {
      "order": 40,
      "isFavorite": false,
      "symbol": "ROOT",
      "color": "#0f0f0f"
    },
    "cryptoValue": "42043.24298"
  },
  {
    "cryptoValue": "4044.843054",
    "coinData": {
      "order": 27,
      "symbol": "SAND",
      "color": "#8cabf2",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "1650.972324",
    "coinData": {
      "order": 22,
      "symbol": "ONDO",
      "isFavorite": false,
      "color": "#202122"
    }
  },
  {
    "cryptoValue": "247.2630145",
    "coinData": {
      "order": 33,
      "symbol": "ATOM",
      "color": "#242428",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "7979.956969",
    "coinData": {
      "isFavorite": false,
      "order": 12,
      "color": "#d6d6d6",
      "symbol": "PORTAL"
    }
  },
  {
    "cryptoValue": "577.6912415",
    "coinData": {
      "order": 28,
      "color": "#4b74d2",
      "isFavorite": false,
      "symbol": "TON"
    }
  },
  {
    "cryptoValue": "5404.228065",
    "coinData": {
      "order": 37,
      "symbol": "MATIC",
      "color": "#8b36d7",
      "isFavorite": false
    }
  },
  {
    "coinData": {
      "color": "#fff333",
      "symbol": "SOL",
      "order": 3,
      "isFavorite": true
    },
    "cryptoValue": "61.54815296"
  },
  {
    "coinData": {
      "order": 34,
      "symbol": "ALGO",
      "color": "#ebebeb",
      "isFavorite": false
    },
    "cryptoValue": "16171.70427"
  },
  {
    "coinData": {
      "symbol": "AAVE",
      "order": 35,
      "isFavorite": false,
      "color": "#9391f7"
    },
    "cryptoValue": "13.55350175"
  },
  {
    "cryptoValue": "4461.59728",
    "coinData": {
      "order": 19,
      "symbol": "ZETA",
      "color": "#005640",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "47897.66423",
    "coinData": {
      "color": "#103ca2",
      "symbol": "SNT",
      "order": 29,
      "isFavorite": true
    }
  },
  {
    "coinData": {
      "order": 18,
      "color": "#0a0a0b",
      "isFavorite": false,
      "symbol": "IMX"
    },
    "cryptoValue": "804.7711321"
  },
  {
    "cryptoValue": "2634.519456",
    "coinData": {
      "symbol": "GTAI",
      "color": "#5394ea",
      "order": 15,
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "16743.03042",
    "coinData": {
      "symbol": "CGPT",
      "isFavorite": false,
      "order": 8,
      "color": "#4a4a4a"
    }
  },
  {
    "cryptoValue": "1332.902188",
    "coinData": {
      "symbol": "RON",
      "isFavorite": false,
      "color": "#453bce",
      "order": 11
    }
  },
  {
    "cryptoValue": "118233.8739",
    "coinData": {
      "color": "#272525",
      "symbol": "PASG",
      "isFavorite": false,
      "order": 39
    }
  },
  {
    "cryptoValue": "7657.606822",
    "coinData": {
      "color": "#ab8349",
      "symbol": "ORNJ",
      "order": 20,
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "142.6517391",
    "coinData": {
      "order": 24,
      "symbol": "PRIME",
      "color": "#beee11",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "97.49227429",
    "coinData": {
      "order": 32,
      "isFavorite": false,
      "color": "#335dd2",
      "symbol": "LINK"
    }
  },
  {
    "cryptoValue": "189.9905122",
    "coinData": {
      "symbol": "ORAI",
      "color": "#000000",
      "isFavorite": false,
      "order": 41
    }
  },
  {
    "coinData": {
      "symbol": "FET",
      "color": "#e3e3d9",
      "isFavorite": false,
      "order": 5
    },
    "cryptoValue": "3877.533636"
  },
  {
    "cryptoValue": "115574.7731",
    "coinData": {
      "color": "#257ef4",
      "symbol": "CUDOS",
      "order": 31,
      "isFavorite": false
    }
  },
  {
    "cryptoValue": "8072.461793",
    "coinData": {
      "symbol": "USDT",
      "isFavorite": false,
      "order": 4,
      "color": "#cce8b5"
    }
  },
  {
    "cryptoValue": "50506.65483",
    "coinData": {
      "color": "#272525",
      "symbol": "ALU",
      "order": 14,
      "isFavorite": false
    }
  },
  {
    "coinData": {
      "color": "#918888",
      "symbol": "GSWIFT",
      "isFavorite": false,
      "order": 9
    },
    "cryptoValue": "14677.51893"
  }
]